import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { init } from "emailjs-com";
import { doc, getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDx8xizTh-l9TjyhT2KMbZKiqkck-q05g4",
    authDomain: "kaantazeweb.firebaseapp.com",
    projectId: "kaantazeweb",
    storageBucket: "kaantazeweb.appspot.com",
    messagingSenderId: "844777444554",
    appId: "1:844777444554:web:b9a0502e8d2acc28599033",
    measurementId: "G-Z733MLDY1N"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const SingleDocument = doc(db, "blogPosts", "6gldc1YmjjLS9cc3MBQe")
init("user_9Q9MnYPiLvG3aHLfrhAwK");

export { app, analytics, db, SingleDocument };
