import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import Header from "../components/layouts/Header";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Make sure to import your db object

function BlogDetails(props) {
  const [blog, setBlog] = useState(null);
  const blogId = props.match.params.id;

  useEffect(() => {
    const fetchBlogDetails = async () => {
      const blogDocRef = doc(db, "blogPosts", blogId); // Assuming your collection is named "blogs"
      const blogDoc = await getDoc(blogDocRef);

      if (blogDoc.exists()) {
        setBlog(blogDoc.data());
        document.title = blogDoc.data().title;
      } else {
        console.error("Blog not found!");
      }
    };

    fetchBlogDetails();
  }, [blogId]);

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  document.body.classList.add("dark");
  // Uncomment the above line if you use dark version

  return (
    <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="blog-single shadow-dark p-30">
              {blog && <Markdown>{blog.content}</Markdown>}
            </div>
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default BlogDetails;
