import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Bachelor’s Degree",
    years: "2016 - 2020",
    content:
      "Bachelor's Degree in Computer Engineering from Karabuk University in Turkey",
  },
];

const experienceData = [
  {
    id: 4,
    title: "Software Engineer",
    years: "May, 2022 - Present",
    content:
      "ETE Technology®, Izmir/Turkey",
  },
  {
    id: 3,
    title: "Software Engineer",
    years: "December, 2020 - April, 2022",
    content:
      "Novartz Bilişim, Adana/Turkey and Inksen Technology via Novartz as Outsource",
  },
  {
    id: 2,
    title: "Software Engineer",
    years: "Sep, 2017 - Nov 2020",
    content:
      "TazeSoft LLC, Turkey",
  }
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
                
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
