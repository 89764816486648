import React, { useState, useEffect } from "react";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";
import { db } from "../../firebase";


function Blogs() {
  

  const [allBlogs, setAllBlogs] = useState([]);
  useEffect(() => {
    const fetchBlogs = async () => {
      const blogCollection = collection(db, "blogPosts"); // Assuming your collection is named "blogs"
      
      // Create a query to fetch the 3 most recent blogs
      const q = query(blogCollection, orderBy("date", "desc"), limit(3));

      const querySnapshot = await getDocs(q);
      const blogs = [];
      querySnapshot.forEach((doc) => {
        blogs.push({
          id: doc.id,
          ...doc.data()
        });
      });
      setAllBlogs(blogs);
    };

    fetchBlogs();
  }, []);
  
  return (
    <section id="blog">
      <div className="container">
        {/* <Pagetitle title="Latest Posts" /> */}
        <h2 className="section-title">Latest Posts</h2>
        <div className="spacer" data-height="20"></div>
        <h5>All the technical blogs moved to the <a href="https://tech.kaantaze.com">tech.kaantaze.com</a>!
        <br /><br />Below you can find the personal posts.</h5>
        <div className="spacer" data-height="60"></div>
        <div className="row blog-wrapper">
          {allBlogs.map((blogItem) => (
            <div className="col-md-4" key={blogItem.id}>
              <Blog blogData={blogItem} />
            </div>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/blogs" className="btn btn-default">
            Show all blogs
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
