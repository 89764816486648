import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

function Blog({ blogData }) {
  const { id, category, title, date, author, imageUrl } = blogData;

  const getNospaceTitle = (title) => {
    // Convert title to lowercase, replace spaces with hyphens, and remove non-alphanumeric characters
    return title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
  };

  const nospaceTitle = getNospaceTitle(title);

  const timestampToDate = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp.seconds * 1000); // Convert to a JavaScript Date object
      return date.toLocaleDateString(); // Convert to a readable date string
    }
    return ""; // Return an empty string or some default value if conversion isn't possible
  };
  
  

  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div className="blog-item rounded bg-white shadow-dark">
        <div className="thumb">
          <a href="!#">
            <span className="category">{category}</span>
          </a>
          <Link to={`blogs/blog-details/${id}/${nospaceTitle}`}>
            <img src={imageUrl} style={{ height: "15em", width:"100%", objectFit: "contain"}} alt="blog-title" />
          </Link>
        </div>
        <div className="details">
          <h4 className="my-0 title">
            <Link to={`blogs/blog-details/${id}/${nospaceTitle}`}>
              {title}
            </Link>
          </h4>
          <ul className="list-inline meta mb-0 mt-2">
            <li className="list-inline-item">{timestampToDate(date)}</li>
            <li className="list-inline-item">{author}</li>
          </ul>
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default Blog;
